import React, { useState } from "react";
import {
	createBrowserRouter,
	RouterProvider,
	redirect,
	useParams,
} from "react-router-dom";
import "./globals.css";
import "./css/normalize.css";
import "./css/webflow.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/rto-bootstrap.css";
import "./css/rto-v3.webflow.css";
import "./css/App.css";
import MoonLoader from "react-spinners/MoonLoader";
import MainLayout from "./layouts/MainLayout";
import Login from "./pages/Login/Index";
import ForgotPassword from "./pages/ForgotPassword/Index";
import ResetPassword from "./pages/ResetPassword/Index";
import ActivateAccount from "./pages/ActivateAccount/Index";
// import DealerCreditCardPayment from "./pages/DealerCreditCardPayment/Index";
// import IsoIndexComponent from "./components/backend/iso/IsoIndexComponent";
import NotFound from "./pages/NotFound/Index";
// import Onboarding from "./pages/Onboarding/Index";
import { getAuthToken, tokenLoader, checkAuthLoader } from "./helpers/Auth";
import Logout from "./pages/Logout/Index";
import Admin from "./pages/Admin/Index";
import AdminTools from "./pages/Admintools/Index";
import AddImei from "./pages/Admintools/AddImei";
import SimplePayment from "./pages/Admintools/SimplePayment";
import Dashboard from "./pages/Dashboard/Index";
import Contracts from "./pages/Contracts/Index";
import ContractsView from "./pages/Contracts/ContractsView/Index";
import Reports from "./pages/Reports/Index";
import Payments from "./pages/Payments/Index";
import SalesTaxReport from "./pages/SalesTaxReport/Index";
import StoreEndOfDayReport from "./pages/StoreEndOfDayReport/Index";
import DefaultedContracts from "./pages/DefaultedContracts/Index";
import DealerPortfolioDetailed from "./pages/DealerPortfolioDetailed/Index";
import DealerContractReconciliationReport from "./pages/DealerContractReconciliationReport/Index";
import Settings from "./pages/Settings/Index";
// import PurchaseOrders from "./pages/PurchaseOrders/Index";
// import Leads from "./pages/Leads/Index";
import ReferralProgram from "./pages/ReferralProgram/Index";
import Trainings from "./pages/Trainings/Index";
import Users from "./pages/Users/Index";
import Isos from "./pages/Isos/Index";
import IsosCreate from "./pages/Isos/Create";
import MasterAgents from "./pages/MasterAgents/Index";
import Organizations from "./pages/Organizations/Index";
import Companies from "./pages/Companies/Index";
import Stores from "./pages/Stores/Index";
import StoresCreate from "./pages/Stores/Create";
import Leads from "./pages/Leads/Index";
import Activities from "./pages/Activities/Index";
import Reminders from "./pages/Reminders/Index";
import { TokenContext } from "./contexts/TokenContext";
import SessionService from "./service/SessionService";
import Contacts from "./pages/Contacts/Index";
import ChangePassword from "./pages/ChangePassword/Index";
import SearchResults from "./pages/SearchResults/Index";
import Resources from "./pages/Resources/Index";
import RtoMobileFees from "./pages/Resources/RtoMobileFees";
import OnboardingView from "./pages/Onboarding/View";
import { canUserActionAccess } from "./helpers/Utils";
import Vendors from "./pages/Vendors/Index";
import VendorProducts from "./pages/VendorProducts/Index";
import VendorModels from "./pages/VendorModels/Index";
import VendorManufacturers from "./pages/VendorManufacturers/Index";
import ContractsView3 from "./pages/Contracts/ContractsView/IndexV3";
import AndroidInstallation from "./pages/Resources/AndroidInstallation";
import AppleInstallation from "./pages/Resources/AppleInstallation";
import ReferralProgramRules from "./pages/Resources/ReferralProgramRules";

// import Onboarding from "./pages/Onboarding/Index";
//import OnboardingForm from "./components/formComponents/OnboardingForm";

import DynamicForm from "./components/formComponents/DynamicForm";
import MultiStepDynamicForm from "./components/formComponents/MultiStepDynamicForm";
import IsoService from "./service/iso/IsoService";
import MasterAgentService from "./service/masterAgent/MasterAgentService";
import OrganizationService from "./service/organization/OrganizationService";
import CompanyService from "./service/company/CompanyService";
import StoreService from "./service/store/StoreService";
import UserService from "./service/user/UserService";
import LeadService from "./service/lead/LeadService";
import ActivityService from "./service/activity/ActivityService";
import ReminderService from "./service/reminder/ReminderService";
import ContactService from "./service/contact/ContactService";
//import TokenizedForm from "./components/formComponents/TokenizedForm";
import OnboardingService from "./service/onboarding/OnboardingService";
import RtoCompanyPayments from "./pages/RtoCompanyPayments/Index";
// import OnboardingService from "./service/onboarding/OnboardingService";

export const API_URL = "https://api-dev.rtomobile.com/api/v1";

function App() {
	const [token, setToken] = useState(getAuthToken());

	const override = {
		display: "block",
		margin: "0 auto",
		borderColor: "#00aaff",
	};

	let [loading, setLoading] = useState(false);
	//let [color, setColor] = useState("#00aaff");
	let color = "#00aaff";

	const router = createBrowserRouter([
		{
			path: "/",
			element: <MainLayout />,
			id: "main",
			loader: tokenLoader,
			children: [
				{
					path: "/",
					element: (
						<Login loading={loading} setLoading={setLoading} />
					),
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader(); // Assuming this returns true if the user is authenticated
							if (isAuthenticated) {
								// Redirect to the dashboard if the user is authenticated
								throw redirect("/dashboard");
							}
							// Stay on the login page if not authenticated
							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/login",
					element: (
						<Login loading={loading} setLoading={setLoading} />
					),
				},
				{ path: "/forgot-password", element: <ForgotPassword /> },
				{ path: "/reset-password", element: <ResetPassword /> },
				{ path: "/activate-account", element: <ActivateAccount /> },
				{
					path: "/admin",
					element: <Admin />,
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/admin/vendors",
					element: (
						<Vendors loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/admin/vendor-products",
					element: (
						<VendorProducts
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "admin/vendors-models",
					element: (
						<VendorModels
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "admin/vendors-manufacturers",
					element: (
						<VendorManufacturers
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/admintools",
					element: <AdminTools />,
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/dashboard",
					element: (
						<Dashboard loading={loading} setLoading={setLoading} />
					),
					loader: checkAuthLoader,
				},
				{
					path: "/contracts",
					element: (
						<Contracts loading={loading} setLoading={setLoading} />
					),
					loader: checkAuthLoader,
				},
				{
					path: "/contracts/view-v2/:id",
					element: (
						<ContractsView
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/contracts/view-v3/:id",
					element: (
						<ContractsView3
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports",
					element: <Reports />,
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-payments",
					element: (
						<Payments loading={loading} setLoading={setLoading} />
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-sales-tax",
					element: (
						<SalesTaxReport
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-end-of-day",
					element: (
						<StoreEndOfDayReport
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-defaulted-contracts",
					element: (
						<DefaultedContracts
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-portfolio-detailed",
					element: (
						<DealerPortfolioDetailed
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/reports/dealer-contract-reconciliation",
					element: (
						<DealerContractReconciliationReport
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/settings",
					element: <Settings />,
					errorElement: <NotFound />,
					loader: checkAuthLoader,
				},
				{
					path: "/search-results",
					element: <SearchResults />,
					errorElement: <NotFound />,
					loader: checkAuthLoader,
				},
				{
					path: "/admintools/add-imei",
					element: (
						<AddImei loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/admintools/simple-payment",
					element: (
						<SimplePayment
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/referral-program",
					element: (
						<ReferralProgram
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/onboarding-v2",
					element: (
						<OnboardingView
							loading={loading}
							setLoading={setLoading}
						/>
					),
					loader: checkAuthLoader,
				},
				{
					path: "/trainings",
					element: <Trainings />,
					loader: checkAuthLoader,
				},
				{
					path: "/resources",
					element: <Resources />,
					loader: checkAuthLoader,
				},
				{
					path: "/resources/rto-mobile-fees",
					element: <RtoMobileFees />,
					loader: checkAuthLoader,
				},
				{
					path: "/resources/android-software-installation-instructions",
					element: <AndroidInstallation />,
					loader: checkAuthLoader,
				},
				{
					path: "/resources/apple-software-installation-instructions",
					element: <AppleInstallation />,
					loader: checkAuthLoader,
				},
				{
					path: "/resources/merchant-referral-program-rules",
					element: <ReferralProgramRules />,
					loader: checkAuthLoader,
				},
				{
					path: "/settings/users",
					element: (
						<Users loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasStoreAdminRole =
								SessionService.canStoreAdmin();

							if (!isAuthenticated || !hasStoreAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/users/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_user"
							modelLabel="User"
							service={UserService}
							apiBase="/user"
							successRedirectBase="settings/users"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasStoreAdminRole =
							// 	SessionService.canStoreAdmin();

							if (!isAuthenticated || !canUserActionAccess()) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/onboarding-v2/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="onboarding"
							modelLabel="Onboarding"
							service={OnboardingService}
							apiBase="/onboarding"
							successRedirectBase="onboarding-v2"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasStoreAdminRole =
							// 	SessionService.canStoreAdmin();

							if (!isAuthenticated || !canUserActionAccess()) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/onboarding-v2/:id",
					element: (
						<MultiStepDynamicForm
							loading={loading}
							setLoading={setLoading}
							model="onboarding"
							modelLabel="Onboarding"
							service={OnboardingService}
							apiBase="/onboarding"
							successRedirectBase="onboarding-v2"
							scenario="update"
							updateId={useParams().id}
							tabs={["activities", "reminders", "contacts"]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasStoreAdminRole =
							// 	SessionService.canStoreAdmin();

							if (!isAuthenticated || !canUserActionAccess()) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/tokenized/:id",
					element: (
						// <TokenizedForm
						// 	loading={loading}
						// 	setLoading={setLoading}
						// />
						<MultiStepDynamicForm
							loading={loading}
							setLoading={setLoading}
							model="onboarding"
							modelLabel="Onboarding"
							service={OnboardingService}
							apiBase="/onboarding"
							successRedirectBase="tokenized"
							scenario="tokenized"
							updateId={useParams().id}
							tabs={[]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasStoreAdminRole =
							// 	SessionService.canStoreAdmin();

							if (!isAuthenticated || !canUserActionAccess()) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/users/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_user"
							modelLabel="User"
							service={UserService}
							apiBase="/user"
							successRedirectBase="settings/users"
							scenario="update"
							updateId={useParams().id}
							tabs={[
								"activities",
								"reminders",
								"userLog",
							]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasStoreAdminRole =
							// 	SessionService.canStoreAdmin();

							if (!isAuthenticated || !canUserActionAccess()) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/isos",
					element: <Isos loading={loading} setLoading={setLoading} />,
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/isos/create-old",
					element: (
						<IsosCreate loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/isos/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_iso"
							modelLabel="ISO"
							service={IsoService}
							apiBase="/iso"
							successRedirectBase="settings/isos"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/isos/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_iso"
							modelLabel="ISO"
							service={IsoService}
							apiBase="/iso"
							successRedirectBase="settings/isos"
							scenario="update"
							updateId={useParams().id}
							tabs={["activities", "reminders", "contacts"]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/master-agents",
					element: (
						<MasterAgents
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasIsoAdminRole =
								SessionService.canIsoAdmin();

							if (!isAuthenticated || !hasIsoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/master-agents/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_master_agent"
							modelLabel="Master Agent"
							service={MasterAgentService}
							apiBase="/master-agent"
							successRedirectBase="settings/master-agents"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							//const hasIsoAdminRole = SessionService.canIsoAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/master-agents/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_master_agent"
							modelLabel="Master Agent"
							service={MasterAgentService}
							apiBase="/master-agent"
							successRedirectBase="settings/master-agents"
							scenario="update"
							updateId={useParams().id}
							tabs={["activities", "reminders", "contacts"]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/organizations",
					element: (
						<Organizations
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasMasterAgentAdminRole =
								SessionService.canMasterAgentAdmin();

							if (!isAuthenticated || !hasMasterAgentAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/organizations/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_organization"
							modelLabel="Organization"
							service={OrganizationService}
							apiBase="/organization"
							successRedirectBase="settings/organizations"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/organizations/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_organization"
							modelLabel="Organization"
							service={OrganizationService}
							apiBase="/organization"
							successRedirectBase="settings/organizations"
							scenario="update"
							updateId={useParams().id}
							tabs={[
								"activities",
								"reminders",
								"contacts",
								"userLog",
							]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/companies",
					element: (
						<Companies loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasOrganizationAdminRole =
								SessionService.canOrganizationAdmin();

							if (!isAuthenticated || !hasOrganizationAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/companies/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_company"
							modelLabel="Company"
							service={CompanyService}
							apiBase="/company"
							successRedirectBase="settings/companies"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasOrganizationAdminRole =
							// SessionService.canOrganizationAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							// if (!isAuthenticated || !hasOrganizationAdminRole) {
							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/companies/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_company"
							modelLabel="Company"
							service={CompanyService}
							apiBase="/company"
							successRedirectBase="settings/companies"
							scenario="update"
							updateId={useParams().id}
							tabs={[
								"activities",
								"reminders",
								"contacts",
								"userLog",
							]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasOrganizationAdminRole =
							// SessionService.canOrganizationAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							// if (!isAuthenticated || !hasOrganizationAdminRole) {
							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/stores",
					element: (
						<Stores loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasCompanyAdminRole =
								SessionService.canCompanyAdmin();

							if (!isAuthenticated || !hasCompanyAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/stores/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_store"
							modelLabel="Store"
							service={StoreService}
							apiBase="/store"
							successRedirectBase="settings/stores"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasCompanyAdminRole =
							// SessionService.canCompanyAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							// if (!isAuthenticated || !hasCompanyAdminRole) {
							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/stores/create-old",
					element: (
						<StoresCreate
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasCompanyAdminRole =
							// SessionService.canCompanyAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							// if (!isAuthenticated || !hasCompanyAdminRole) {
							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/settings/stores/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_store"
							modelLabel="Store"
							service={StoreService}
							apiBase="/store"
							successRedirectBase="settings/stores"
							scenario="update"
							updateId={useParams().id}
							tabs={[
								"activities",
								"reminders",
								"contacts",
								"store_products",
								"store_report_flags",
								"ccn_info",
								"ccn_transactions",
								"userLog",
							]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							// const hasCompanyAdminRole =
							// SessionService.canCompanyAdmin();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							// if (!isAuthenticated || !hasCompanyAdminRole) {
							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/leads",
					element: (
						<Leads loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasIsoAdminRole =
								SessionService.canIsoAdmin();

							if (!isAuthenticated || !hasIsoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/leads/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="leads"
							modelLabel="Lead"
							service={LeadService}
							apiBase="/lead"
							successRedirectBase="leads"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasIsoAdminRole =
								SessionService.canIsoAdmin();

							if (!isAuthenticated || !hasIsoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/leads/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="leads"
							modelLabel="Lead"
							service={LeadService}
							apiBase="/lead"
							successRedirectBase="leads"
							scenario="update"
							updateId={useParams().id}
							tabs={["activities", "reminders", "contacts"]}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasIsoAdminRole =
								SessionService.canIsoAdmin();

							if (!isAuthenticated || !hasIsoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				// activities
				{
					path: "/activities",
					element: (
						<Activities loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/activities/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_activity"
							modelLabel="Activity"
							service={ActivityService}
							apiBase="/activity"
							successRedirectBase="activities"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/activities/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_activity"
							modelLabel="Activity"
							service={ActivityService}
							apiBase="/activity"
							successRedirectBase="activities"
							scenario="update"
							updateId={useParams().id}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				// reminders
				{
					path: "/reminders",
					element: (
						<Reminders loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/reminders/create",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_reminder"
							modelLabel="Reminder"
							service={ReminderService}
							apiBase="/reminder"
							successRedirectBase="reminders"
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/reminders/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_reminder"
							modelLabel="Reminder"
							service={ReminderService}
							apiBase="/reminder"
							successRedirectBase="reminders"
							scenario="update"
							updateId={useParams().id}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/admintools/rto-company-payments",
					element: (
						<RtoCompanyPayments
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/change-password",
					element: (
						<ChangePassword
							loading={loading}
							setLoading={setLoading}
						/>
					),
					errorElement: <NotFound />,
					loader: checkAuthLoader,
				},
				{
					path: "/contacts",
					element: (
						<Contacts loading={loading} setLoading={setLoading} />
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "/contacts/:id",
					element: (
						<DynamicForm
							loading={loading}
							setLoading={setLoading}
							model="struct_contact"
							modelLabel="Contact"
							service={ContactService}
							apiBase="/contact"
							successRedirectBase="contacts"
							scenario="update"
							updateId={useParams().id}
						/>
					),
					errorElement: <NotFound />,
					loader: async () => {
						try {
							const isAuthenticated = !checkAuthLoader();
							const hasRtoAdminRole =
								SessionService.canRtoAdmin();

							if (!isAuthenticated || !hasRtoAdminRole) {
								throw new Error("Unauthorized");
							}

							return null;
						} catch (error) {
							throw error;
						}
					},
				},
				{
					path: "*",
					element: <NotFound />,
				},
			],
		},
		// { path: "/onboarding", element: <Onboarding /> },
		{
			path: "*",
			element: <MainLayout />,
		},
		{
			path: "logout",
			action: () => Logout(setToken),
			loader: tokenLoader,
		},
	]);

	return (
		<TokenContext.Provider value={{ token, setToken }}>
			<div
				{...(!loading && { style: { display: "none" } })}
				{...(loading && {
					className:
						"fixed-top h-100 d-flex align-items-center justify-content-center",
					style: { backgroundColor: "rgb(255 255 255 / 70%)" },
				})}
			>
				<MoonLoader
					color={color}
					loading={loading}
					cssOverride={override}
					size={80}
					speedMultiplier={0.8}
					aria-label="Loading Spinner"
					data-testid="loader"
				/>
			</div>
			<RouterProvider router={router} />
		</TokenContext.Provider>
	);
}

export default App;
