import React, { useState } from "react";
import { Modal, Form, Row, Col, Spinner, Button } from "react-bootstrap";
import DashboardService from "../../service/dashboard/DashboardService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
// import { zodResolver } from "@hookform/resolvers/zod";
// import * as z from "zod";
// import Feedback from "react-bootstrap/esm/Feedback";

const CalculatorModal = ({ show, setShow, animation, setAnimation }) => {
	const [modalLoading, setModalLoading] = useState(false);
	const [response, setResponse] = useState({});
	const [count, setCount] = useState(0);
	const { setValue, handleSubmit } = useForm();

	function calculatePercentValue(number, percent) {
		return Number((number * (parseFloat(percent) / 100)).toFixed(2));
	}

	const handleModalClose = (event) => {
		setCount(0);
		setAnimation("");
		setInputs({
			retailValue: 0,
			downPayment: 0,
			pricingFactor: 0,
			totalContractValue: 0,
		});
		// Set a timeout equal to the animation duration before hiding the modal
		// setTimeout(() => {
		//   setShow(false);
		//   setAnimation("");
		// }, 700);

		setShow(false);
	};

	const [inputs, setInputs] = useState({
		retailValue: 0,
		downPayment: 0,
		pricingFactor: 0,
		totalContractValue: 0,
	});

	const [lastValues, setLastValues] = useState({
		...inputs,
	});

	const handleChange = (e) => {
		const { name, value } = e.target;

		if (name === "retailValue") {
			setInputs((prevInputs) => ({
				...prevInputs,
				[name]:
					Number(value) >
					Number(response?.store_limits?.contract_max_value)
						? Number(response?.store_limits?.contract_max_value)
						: value,
			}));
		} else {
			setInputs((prevInputs) => ({
				...prevInputs,
				[name]: Number(value),
			}));
		}
	};

	const handleBlur = async (e) => {
		let compareInput = inputs;

		if (!compareInput.retailValue || compareInput.retailValue === "NaN") {
			compareInput.retailValue = 0.0;
		} else {
			compareInput.retailValue = Number(inputs?.retailValue).toFixed(2);
		}

		if (JSON.stringify(compareInput) !== JSON.stringify(lastValues)) {
			!inputs.retailValue &&
				inputs.retailValue === "NaN" &&
				setInputs((prevInputs) => ({
					...prevInputs,
					retailValue: 0,
				}));

			let payload = {
				PaymentCalculator: {
					invoiceTotal:
						inputs?.retailValue !== "NaN" && inputs?.retailValue
							? Math.min(
									Number(
										response?.store_limits
											?.contract_max_value
									),
									Number(inputs?.retailValue)
							  )
							: 0,
					amountDown:
						count === 0
							? calculatePercentValue(
									Number(inputs.retailValue),
									Number(
										response?.store_limits?.down_payment
											?.default
									)
							  )
							: Number(inputs.downPayment),
					financeFactor:
						count === 0
							? Number(response?.store_limits?.finance?.default)
							: Number(inputs.pricingFactor),
					displayType: 1,
					hasLock: 1,
				},
			};
			let newCount = count + 1;
			setCount(newCount);

			setModalLoading(true);

			try {
				// Additional logic for 'amount_down'
				const result = await DashboardService.getCalculator(payload);
				setModalLoading(false);
				let res = result?.data?.data?.query;
				setInputs((prevInputs) => ({
					...prevInputs,
					retailValue: parseFloat(inputs?.retailValue).toFixed(2),
					pricingFactor: res?.data?.finance_factor,
					downPayment: parseFloat(
						res?.data?.down_payment?.net
					).toFixed(2),
					totalContractValue: parseFloat(
						res?.data?.contract?.net
					).toFixed(2),
				}));

				setResponse(res);
				setLastValues((prevInputs) => ({
					...prevInputs,
					retailValue: parseFloat(inputs?.retailValue).toFixed(2),
					pricingFactor: res?.data?.finance_factor,
					downPayment: parseFloat(
						res?.data?.down_payment?.net
					).toFixed(2),
					totalContractValue: parseFloat(
						res?.data?.contract?.net
					).toFixed(2),
				}));
			} catch (error) {
				console.error("Error on API call:", error);
				setModalLoading(false);
			}
		}
	};

	const handleOnShow = async () => {
		let payload = {
			PaymentCalculator: {
				invoiceTotal: Number(inputs.retailValue),
				amountDown: Number(inputs.downPayment),
				financeFactor: Number(inputs.pricingFactor),
				displayType: 0,
				hasLock: 1,
			},
		};

		setModalLoading(true);

		try {
			// Additional logic for 'amount_down'
			const result = await DashboardService.getCalculator(payload);
			setModalLoading(false);
			let res = result?.data?.data?.query;
			setInputs((prevInputs) => ({
				...prevInputs,
				pricingFactor: parseFloat(
					res?.store_limits?.finance?.default
				).toFixed(2),
				downPayment: parseFloat(res?.data?.down_payment?.net).toFixed(
					2
				),
				totalContractValue: parseFloat(
					res?.data?.contract?.net
				).toFixed(2),
			}));

			setResponse(res);
		} catch (error) {
			console.error("Error on API call:", error);
			setModalLoading(false);
		}
	};

	return (
		<Modal
			show={show}
			onHide={handleModalClose}
			size="lg"
			backdrop="static"
			centered
			dialogClassName={animation}
			onExited={() => setAnimation("")}
			onShow={handleOnShow}
		>
			<Modal.Header closeButton>
				<Modal.Title className="custom-modal-title">
					Contract Calculator
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{modalLoading && (
					<div className="modal-backdrop-spinner">
						<Spinner
							className="custom-spinner"
							animation="border"
							role="status"
							variant="primary"
							size="lg"
						>
							<span className="visually-hidden">Loading...</span>
						</Spinner>
					</div>
				)}
				<Form onSubmit={handleSubmit(handleBlur)}>
					<button type="submit" style={{ display: "none" }}>
						Submit
					</button>
					<Row className="g-4">
						<Col xs={12} md={6}>
							<Row className="" style={{ rowGap: "10px" }}>
								<Col xs={12}>
									<Form.Group as={Row}>
										<Form.Label
											column
											md={7}
											className="input-label"
										>
											Retail Value $:
										</Form.Label>
										<Col md={5}>
											<Form.Control
												htmlSize={"number"}
												step={"any"}
												type="number"
												name="retailValue"
												value={inputs.retailValue}
												// {...register("retailValue", {
												//   setValueAs: (value) => (value === "" ? 0 : Number(value)),
												//   valueAsNumber: true,
												// })}
												// isInvalid={
												//   touchedFields.retailValue && !!errors["retailValue"]
												// }
												// isValid={
												//   touchedFields.retailValue && !errors["retailValue"]
												// }
												onBlur={handleBlur}
												onChange={handleChange}
											/>
											{/* <Feedback type="invalid">
                    {errors["retailValue"] && errors["retailValue"].message}
                  </Feedback> */}
										</Col>
									</Form.Group>
								</Col>
								<Col
									xs={12}
									style={{
										fontSize: "0.7rem",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{response &&
										`Retail max value: $${Number(
											response?.store_limits
												?.contract_max_value
										).toFixed(2)}`}
								</Col>
							</Row>
						</Col>
						<Col xs={12} md={6}>
							<Row className="" style={{ rowGap: "10px" }}>
								<Col xs={12}>
									<Form.Group as={Row}>
										<Form.Label
											column
											md={7}
											className="input-label"
										>
											{
												response?.data?.down_payment
													?.percent
											}
											% Down Payment $:
										</Form.Label>
										<Col md={5}>
											<Form.Control
												htmlSize={"number"}
												step={"any"}
												type="number"
												name="downPayment"
												value={inputs.downPayment}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Form.Group>
								</Col>
								<Col
									xs={12}
									style={{
										fontSize: "0.7rem",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{response &&
										`$${calculatePercentValue(
											Number(inputs.retailValue),
											Number(
												response?.store_limits
													?.down_payment?.min
											)
										).toFixed(2)} min ${
											response?.store_limits?.down_payment
												?.min
										}% | $${calculatePercentValue(
											Number(inputs.retailValue),
											Number(
												response?.store_limits
													?.down_payment?.max
											)
										).toFixed(2)} max ${
											response?.store_limits?.down_payment
												?.max
										}% | $${calculatePercentValue(
											Number(inputs.retailValue),
											Number(
												response?.store_limits
													?.down_payment?.default
											)
										).toFixed(2)} default ${
											response?.store_limits?.down_payment
												?.default
										}%`}
								</Col>
							</Row>
						</Col>

						<Col xs={12} md={6}>
							<Row className="" style={{ rowGap: "10px" }}>
								<Col xs={12}>
									<Form.Group as={Row}>
										<Form.Label
											column
											md={7}
											className="input-label"
										>
											Pricing Factor:
										</Form.Label>
										<Col md={5}>
											<Form.Control
												htmlSize={"number"}
												step={"any"}
												type="number"
												name="pricingFactor"
												value={inputs.pricingFactor}
												onChange={handleChange}
												onBlur={handleBlur}
											/>
										</Col>
									</Form.Group>
								</Col>

								<Col
									xs={12}
									style={{
										fontSize: "0.7rem",
										display: "flex",
										justifyContent: "center",
									}}
								>
									{response &&
										`min ${response?.store_limits?.finance?.min} | max ${response?.store_limits?.finance?.max} | default ${response?.store_limits?.finance?.default}`}
								</Col>
							</Row>
						</Col>
						<Col xs={12} md={6}>
							<Form.Group as={Row}>
								<Form.Label
									column
									md={7}
									className="input-label"
								>
									Total Contract Value $:
								</Form.Label>
								<Col md={5}>
									<Form.Control
										disabled
										type="number"
										name="totalContractValue"
										value={inputs.totalContractValue}
										// onChange={handleChange}
										// onBlur={handleBlur}
									/>
								</Col>
							</Form.Group>
						</Col>
					</Row>
				</Form>
				<hr></hr>
				<Row>
					<Col xs={12}>
						<span
							style={{
								fontSize: "0.8rem",
								// display: "flex",
								// justifyContent: "center",
							}}
						>
							Today's Down Payment of $
							{parseFloat(
								response?.data?.down_payment?.net
							).toFixed(2)
								? parseFloat(
										response?.data?.down_payment?.net
								  ).toFixed(2)
								: 0.0}
							, Sales Tax of $
							{parseFloat(
								response?.data?.down_payment?.tax
							).toFixed(2)
								? parseFloat(
										response?.data?.down_payment?.tax
								  ).toFixed(2)
								: 0.0}
							, and Payment Fee of $
							{parseFloat(
								response?.store_limits?.payment_fee
							).toFixed(2)
								? parseFloat(
										response?.store_limits?.payment_fee
								  ).toFixed(2)
								: 0.0}{" "}
							totalling{" "}
							<strong style={{ textDecoration: "underline" }}>
								$
								{(
									parseFloat(
										response?.store_limits?.payment_fee
									) +
									parseFloat(
										response?.data?.down_payment?.tax
									) +
									parseFloat(
										response?.data?.down_payment?.net
									)
								).toFixed(2)
									? (
											parseFloat(
												response?.store_limits
													?.payment_fee
											) +
											parseFloat(
												response?.data?.down_payment
													?.tax
											) +
											parseFloat(
												response?.data?.down_payment
													?.net
											)
									  ).toFixed(2)
									: 0.0}
							</strong>{" "}
							is due today.
						</span>
					</Col>
					<Col
						xs={12}
						className="mt-2"
						// style={{ maxHeight: "340px", overflowY: "scroll" }}
					>
						<TableContainer
							component={Paper}
							// style={{ maxHeight: 300, overflow: "auto" }}
							className="rounded-table table-striped-rto customScrollbar"
						>
							<Table>
								<TableHead className="blue-header ">
									<TableRow>
										<TableCell
											align="center"
											className="blue-header"
										>
											Lease Term
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Weekly Lease
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Bi-Weekly Lease
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Monthly Lease
										</TableCell>
									</TableRow>
								</TableHead>
							</Table>
							<div
								style={{ overflow: "auto", height: "300px" }}
								className="table-striped-rto customScrollbar"
							>
								<Table style={{ tableLayout: "fixed" }}>
									<TableBody>
										{response?.data?.available_plans ? (
											Object.keys(
												response.data.available_plans
											).map((key) => (
												<TableRow
													key={key}
													sx={{
														"&:last-child td, &:last-child th":
															{
																border: 0,
															},
													}}
												>
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
														component="th"
														scope="row"
													>
														{key} Month
													</TableCell>
													{response.data
														.available_plans[key]
														.Weekly ? (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															$
															{
																response.data
																	.available_plans[
																	key
																].Weekly
																	.payment_amount
															}{" "}
															<span
																style={{
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	response
																		.data
																		.available_plans[
																		key
																	].Weekly
																		.num_payments
																}
															</span>
														</TableCell>
													) : (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															N/A
														</TableCell>
													)}
													{response.data
														.available_plans[key]
														.Biweekly ? (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															$
															{
																response.data
																	.available_plans[
																	key
																].Biweekly
																	.payment_amount
															}{" "}
															<span
																style={{
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	response
																		.data
																		.available_plans[
																		key
																	].Biweekly
																		.num_payments
																}
															</span>
														</TableCell>
													) : (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															N/A
														</TableCell>
													)}
													{response.data
														.available_plans[key]
														.Monthly ? (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															$
															{
																response.data
																	.available_plans[
																	key
																].Monthly
																	.payment_amount
															}{" "}
															<span
																style={{
																	fontSize:
																		"smaller",
																}}
															>
																x{" "}
																{
																	response
																		.data
																		.available_plans[
																		key
																	].Monthly
																		.num_payments
																}
															</span>
														</TableCell>
													) : (
														<TableCell
															align="center"
															style={{
																backgroundColor:
																	"transparent",
															}}
														>
															N/A
														</TableCell>
													)}
												</TableRow>
											))
										) : (
											<TableRow>
												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
												<TableCell></TableCell>
											</TableRow>
										)}
									</TableBody>
								</Table>
							</div>
						</TableContainer>

						{/* <TableContainer
							component={Paper}
							style={{ maxHeight: 300, overflow: "auto" }}
							className="rounded-table table-striped-rto customScrollbar"
						>
							<Table stickyHeader aria-label="sticky table">
								<TableHead className="blue-header ">
									<TableRow>
										<TableCell
											align="center"
											className="blue-header"
										>
											Lease Term
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Weekly Lease
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Bi-Weekly Lease
										</TableCell>
										<TableCell
											align="center"
											className="blue-header"
										>
											Monthly Lease
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{response?.data?.available_plans ? (
										Object.keys(
											response.data.available_plans
										).map((key) => (
											<TableRow
												key={key}
												sx={{
													"&:last-child td, &:last-child th":
														{
															border: 0,
														},
												}}
											>
												<TableCell
													align="center"
													style={{
														backgroundColor:
															"transparent",
													}}
													component="th"
													scope="row"
												>
													{key} Month
												</TableCell>
												{response.data.available_plans[
													key
												].Weekly ? (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														$
														{
															response.data
																.available_plans[
																key
															].Weekly
																.payment_amount
														}{" "}
														<span
															style={{
																fontSize:
																	"smaller",
															}}
														>
															x{" "}
															{
																response.data
																	.available_plans[
																	key
																].Weekly
																	.num_payments
															}
														</span>
													</TableCell>
												) : (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														N/A
													</TableCell>
												)}
												{response.data.available_plans[
													key
												].Biweekly ? (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														$
														{
															response.data
																.available_plans[
																key
															].Biweekly
																.payment_amount
														}{" "}
														<span
															style={{
																fontSize:
																	"smaller",
															}}
														>
															x{" "}
															{
																response.data
																	.available_plans[
																	key
																].Biweekly
																	.num_payments
															}
														</span>
													</TableCell>
												) : (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														N/A
													</TableCell>
												)}
												{response.data.available_plans[
													key
												].Monthly ? (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														$
														{
															response.data
																.available_plans[
																key
															].Monthly
																.payment_amount
														}{" "}
														<span
															style={{
																fontSize:
																	"smaller",
															}}
														>
															x{" "}
															{
																response.data
																	.available_plans[
																	key
																].Monthly
																	.num_payments
															}
														</span>
													</TableCell>
												) : (
													<TableCell
														align="center"
														style={{
															backgroundColor:
																"transparent",
														}}
													>
														N/A
													</TableCell>
												)}
											</TableRow>
										))
									) : (
										<TableRow>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
											<TableCell></TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer> */}
					</Col>
				</Row>
			</Modal.Body>
			<Modal.Footer>
				<Button
					variant="primary"
					className="custom-button"
					onClick={handleModalClose}
				>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default CalculatorModal;
