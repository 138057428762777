import React, { useState } from "react";
import { Button } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveFile } from "../../../../helpers/Utils";

/**
 * @doc when we need to copy the content to another textbox
 * "download" => [
	"type" => "download",
	"fileName" => "File Name .png",
	"fileType" => "image/png",
	"fileData" => "...........BLOG CONTENT............",
 ]
 */
/**
 * Download component
 * @param {string} fileType
 * @param {string} fileName
 * @param {string} fileData
 * @param {string} filePath -> if file is directly accessible via url
 * @param {string} className
 * @param {string} modelName - the model we need to update
 * @param {string} modelId - the id of the model we need to update
 * @param {object} otherProps
 * @returns
 */
const CustomDownloadComponent = ({
	fileType,
	fileName,
	fileData,
	filePath,
	className,
	modelName,
	modelId,
	...otherProps
}) => {
	let downloadable =
		(filePath && filePath.length > 0) || (fileData && fileData.length > 0);

	const handleDownload = () => {
		if (!downloadable) {
			return;
		}

		// if direct load via url
		if (filePath) {
			// Simulate downloading the file by creating an anchor element
			const link = document.createElement("a");
			link.href = filePath;
			link.download = filePath.substring(filePath.lastIndexOf("/") + 1);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		} else {
			//@todo - We need update the logic to handle with file download via ajax
			saveFile(fileType, fileData, fileName);
		}
	};

	return (
		<div
			className={`${className}`}
			style={{
				display: "flex",
				justifyContent: "left",
				alignItems: "left",
			}}
		>
			{/* Button with Text and Icon */}
			<Button
				variant="outlined"
				size="small"
				disabled={!downloadable}
				onClick={handleDownload}
				style={{ marginRight: "5px" }}
				{...otherProps}
			>
				<CloudDownloadIcon />
			</Button>
			{fileName}
		</div>
	);
};

export default CustomDownloadComponent;
