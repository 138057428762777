import React, { useState } from "react";
import { message, Upload } from "antd";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ApiService from "../../../../service/ApiService";

/**
 * Upload component for the form
 * @ref https://ant.design/components/upload/
 * @doc API response format
 * "upload" => [
		"type" => "upload",
		"name" => "referral_logo",
		"class" => "col-md-12",
		'maxCount' => 1,
		'allowedFileExtensions' => ['jpg', 'jpeg', 'png', 'gif'],
		'maxFileSize' => 100, // KB
		'modelName' => 'struct-master-agent',
		'modelId' => 123,
		'listType' => 'text',
 *	],
 *
 *
 * @param {string} name
 * @param {string} label
 * @param {string} modelName - the model we need to update
 * @param {string} modelId - the id of the model we need to update
 * @param {string} className
 * @param {array} allowedFileExtensions [
		".jpeg",
		".jpg",
		".png",
		".gif",
		".pdf",
		".doc",
		".docx", etc
	];
 * @param {number} maxCount
 * @param {number} maxFileSize values in KB
 * @param {string} listType - text, picture, picture-card,picture-circle
 * @returns
 */
const CustomUploadComponent = ({
	name,
	label,
	modelName,
	modelId,
	className,
	allowedFileExtensions,
	maxCount,
	maxFileSize,
	listType,
	...otherProps
}) => {
	const [isValid, setIsValid] = useState(false);

	const handleCustomRequest = async ({ file, onSuccess, onError }) => {
		const formData = new FormData();
		formData.append("file", file);

		try {
			//@todo: Add modelName and modelId to the form data before sending
			return await ApiService.postFormData("/onboarding/upload", formData)
				.then((response) => {
					if (response.data.isOk) {
						onSuccess(response.data, file);
						message.success(`${file.name} uploaded successfully`);
					} else {
						onError(response.data);
						message.error(`Failed to upload ${file.name}`);
					}
				})
				.catch((error) => {
					onError(error.data);
					message.error(`Error uploading ${file.name}: ${error}`);
				});
		} catch (error) {
			console.error("Error uploading file:", error);
			onError(error);
		}
	};

	const beforeUpload = (file, onSuccess, onError) => {
		let isAllowedExtension = false;

		// If the allowedFileExtensions array is empty, allow the upload
		if (allowedFileExtensions.length === 0) {
			isAllowedExtension = true;
		} else {
			let fileName = file.name.toLowerCase();
			let extension = fileName.substring(fileName.lastIndexOf(".") + 1);
			isAllowedExtension = allowedFileExtensions.includes(extension);
			if (!isAllowedExtension) {
				message.error(
					"You can only upload " +
						allowedFileExtensions.join(", ") +
						" files!"
				);
			}
		}

		if (isAllowedExtension) {
			// Check file size
			if (maxFileSize) {
				if (file.size / 1024 > maxFileSize) {
					file.staus = "error";
					message.error(
						`File must be smaller than ${maxFileSize}KB!`
					);
					isAllowedExtension = false;
				}
			}
		}
		setIsValid(isAllowedExtension);
		// Check
		return isAllowedExtension; // Return true if the file extension is allowed, false otherwise
	};

	const handleRemove = async (file) => {
		console.log("Delete File", file);
	};

	return (
		<div
			className={`${className}`}
			style={{ display: "flex", justifyContent: "center" }}
		>
			<Upload
				name={name}
				customRequest={handleCustomRequest}
				onRemove={handleRemove}
				maxCount={maxCount ? maxCount : 1}
				beforeUpload={beforeUpload}
				listType={listType ? listType : "text"}
			>
				<Button variant="outlined" size="small">
					<CloudUploadIcon
						style={
							label?.length > 0 ? { paddingRight: "5px" } : null
						}
					/>
					{label}
				</Button>
			</Upload>
		</div>
	);
};

export default CustomUploadComponent;
