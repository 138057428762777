/**
 * Get model URL
 * @param {int} modelId
 * @param {string} modelName
 */
export function getModelUrl(modelId, modelName) {
	let path = "";
	switch (modelName) {
		case "struct_iso":
			path = `/settings/isos/${modelId}`;
			break;
		case "struct_master_agent":
			path = `/settings/master-agents/${modelId}`;
			break;
		case "struct_organization":
			path = `/settings/organizations/${modelId}`;
			break;
		case "struct_company":
			path = `/settings/companies/${modelId}`;
			break;
		case "struct_store":
			path = `/settings/stores/${modelId}`;
			break;
		case "user":
			path = `/settings/users/${modelId}`;
			break;
		case "leads":
			path = `/leads/${modelId}`;
			break;
		case "onboarding":
			path = `/onboarding/${modelId}`;
			break;
		default:
			path = "";
	}
	return path;
}
