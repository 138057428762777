import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/common/TabPanel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import IndexTable from "../components/common/IndexTable";
import StoreProductsModelTable from "./backend/models/StoreProductsModelTable";
import StoreReportFlagsModelTable from "./backend/models/StoreReportFlagsModelTable";
import CCNInfoForm from "./backend/models/CCNInfoForm";
import ActivityService from "../service/activity/ActivityService";
import ReminderService from "../service/reminder/ReminderService";
import ContactService from "../service/contact/ContactService";
import UserLogService from "../service/user/UserLogService";
import RtoCompanyPaymentsService from "../service/rtoCompanyPayments/RtoCompanyPaymentsService";

const theme = createTheme({
	palette: {
		primary: {
			main: "#00ABF9",
			light: "#A3DAFA",
			dark: "#0091d3",
			contrastText: "#fff",
		},
	},
	components: {
		MuiPopover: {
			styleOverrides: {
				paper: {
					padding: "1rem",
					maxWidth: "500px", // Set the width of the dialog window
					maxHeight: "500px", // Set the max height of the dialog window
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					fontWeight: "600",
					textTransform: "none",
					whiteSpace: "nowrap",
				},
			},
		},
	},
});

function ModelActivities({
	redirectPath,
	modelId,
	model,
	loading,
	setLoading,
	tabs = [],
}) {
	const [activeTab, setActiveTab] = useState(0); // Initialize with the default tab
	const fetchCount = useRef(0);

	const handleTabChange = (event, tab) => {
		setActiveTab(tab);
	};

	// Mapping of tab names to their titles and services
	const tabConfig = {
		activities: {
			label: "Activities",
			service: ActivityService,
			actionButtons: {
				create: {
					enabled: true,
					path: "/create",
				},
				updateModal: {
					enabled: true,
					path: "/update",
				},
				delete: {
					enabled: true,
					path: "/delete",
				},
			},
			labels: [
				{
					id: "id",
					label: "ID",
					excluded: true,
				},
				{
					id: "actities_type_name",
					label: "Type",
				},
				{
					id: "assigned_to_username",
					label: "Assigned To",
				},
				{
					id: "description",
					label: "Description",
				},
				{
					id: "created_at",
					label: "Created At",
					displayTime: true,
				},
			],
			apiBase: "/activity",
			successRedirectBase: redirectPath,
		},
		reminders: {
			label: "Reminders",
			service: ReminderService,
			actionButtons: {
				create: {
					enabled: true,
					path: "/create",
				},
				updateModal: {
					enabled: true,
					path: "/update",
				},
				markAsDone: {
					enabled: true,
				},
				delete: {
					enabled: true,
					path: "/delete",
				},
			},
			labels: [
				{
					id: "id",
					label: "ID",
					excluded: true,
				},
				{
					id: "reminders_type_name",
					label: "Type",
				},
				{
					id: "reminder_status_name",
					label: "Status",
				},
				{
					id: "assigned_to_name",
					label: "Assigned To",
				},
				{
					id: "date",
					label: "Due Date",
				},
				{
					id: "description",
					label: "Description",
				},
				{
					id: "created_at",
					label: "Created At",
				},
				{
					id: "model_id",
					excluded: true,
				},
				{
					id: "model",
					excluded: true,
				},
			],
			apiBase: "/reminder",
			successRedirectBase: redirectPath,
		},
		contacts: {
			label: "Contacts",
			service: ContactService,
			actionButtons: {
				create: {
					enabled: true,
					path: "/create",
				},
				updateModal: {
					enabled: true,
					path: "/update",
				},
				delete: {
					enabled: true,
					path: "/delete",
				},
			},
			labels: [
				{
					id: "id",
					label: "ID",
					excluded: true,
				},
				{
					id: "first_name",
					label: "First Name",
				},
				{
					id: "last_name",
					label: "Last Name",
				},
				{
					id: "email",
					label: "Email",
				},
				{
					id: "phone",
					label: "Phone",
				},
				{
					id: "points_of_contact_name",
					label: "Title",
				},
				{
					id: "created_at",
					label: "Created At",
				},
			],
			apiBase: "/contact",
			successRedirectBase: redirectPath,
		},
		store_products: {
			label: "Products",
			service: null,
		},
		store_report_flags: {
			label: "Report Config",
			service: null,
		},
		userLog: {
			label: "User Log",
			service: UserLogService,
			actionButtons: {},
			labels: [
				{
					id: "user_first_name",
					label: "First Name",
				},
				{
					id: "user_last_name",
					label: "Last Name",
				},
				{
					id: "user_name",
					label: "Username",
				},
				{
					id: "action",
					label: "Action",
				},
				{
					id: "struct_store_name",
					label: "Store Name",
				},
				{
					id: "ip_address",
					label: "IP Address",
				},
				{
					id: "timestamp",
					label: "Created At",
					displayTime: true,
				},
			],
			apiBase: "/user-log",
			successRedirectBase: redirectPath,
		},
		ccn_info: {
			label: "CCN Info",
			service: null,
		},
		ccn_transactions: {
			label: "CCN Transactions",
			service: RtoCompanyPaymentsService,
			actionButtons: {
				// create: {
				// 	enabled: true,
				// 	path: "/create",
				// },
				// updateModal: {
				// 	enabled: true,
				// 	path: "/update",
				// },
				// delete: {
				// 	enabled: true,
				// 	path: "/delete",
				// },
				void: {
					enabled: true,
				},
				refund: {
					enabled: true,
				},
			},
			labels: [
				{
					id: "transaction_number",
					label: "Transaction#",
				},
				{
					id: "mid",
					label: "MID",
				},
				{
					id: "struct_store_id",
					label: "Store ID",
					excluded: true,
				},
				{
					id: "contracts_payment_status_id",
					label: "Status ID",
					excluded: true,
				},
				{
					id: "date",
					label: "Date",
				},
				{
					id: "method",
					label: "Method",
				},
				{
					id: "name",
					label: "Name",
				},
				{
					id: "brand",
					label: "Brand",
				},
				{
					id: "last_4",
					label: "Last 4",
				},
				{
					id: "amount",
					label: "Amount",
					currency: true,
				},
				{
					id: "auth_code",
					label: "Auth Code",
				},
				{
					id: "contracts_payment_status_name",
					label: "Status",
				},
			],
			apiBase: "/rto-company-payments",
			successRedirectBase: redirectPath,
		},
	};

	return (
		<>
			{tabs.length > 0 && (
				<div
					className="card card-primary card-outline card-outline-tabs"
					style={{
						borderTop: "3px solid #00aaff",
						boxShadow:
							"0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
					}}
				>
					<ThemeProvider theme={theme}>
						<Box sx={{ width: "100%" }}>
							<Box
								sx={{ borderBottom: 1, borderColor: "divider" }}
							>
								<Tabs
									value={activeTab}
									onChange={handleTabChange}
								>
									{tabs.map((tab, index) => (
										<Tab
											key={index}
											label={tabConfig[tab].label}
										/>
									))}
								</Tabs>
							</Box>
							{tabs.map((tab, index) => (
								<TabPanel
									key={index}
									value={activeTab}
									index={index}
								>
									{activeTab === index &&
										model &&
										modelId &&
										[
											"activities",
											"reminders",
											"contacts",
											"userLog",
											"ccn_transactions",
										].includes(tab) && (
											//tab !== "store_report_flags" && (
											<IndexTable
												modelId={modelId}
												model={model}
												fetchCount={fetchCount}
												loading={loading}
												setLoading={setLoading}
												service={tabConfig[tab].service}
												title={tabConfig[tab].label}
												actionButtons={
													tabConfig[tab].actionButtons
												}
												labels={tabConfig[tab].labels}
												apiBase={tabConfig[tab].apiBase}
												successRedirectBase={
													tabConfig[tab]
														.successRedirectBase
												}
											/>
										)}
									{activeTab === index &&
										model &&
										modelId &&
										tab === "store_report_flags" && (
											<StoreReportFlagsModelTable
												modelId={modelId}
												model={model}
												fetchCount={fetchCount}
												loading={loading}
												setLoading={setLoading}
											/>
										)}
									{activeTab === index &&
										model &&
										modelId &&
										tab === "store_products" && (
											<StoreProductsModelTable
												modelId={modelId}
												model={model}
												fetchCount={fetchCount}
												loading={loading}
												setLoading={setLoading}
											/>
										)}
									{activeTab === index &&
										model &&
										modelId &&
										tab === "ccn_info" && (
											<CCNInfoForm
												modelId={modelId}
												model={model}
												fetchCount={fetchCount}
												loading={loading}
												setLoading={setLoading}
											/>
										)}
									{/* {activeTab === index &&
										model &&
										modelId &&
										tab === "ccn_transactions" && (
											<CCNTransactionsTable
												modelId={modelId}
												model={model}
												fetchCount={fetchCount}
												loading={loading}
												setLoading={setLoading}
											/>
										)} */}
								</TabPanel>
							))}
						</Box>
					</ThemeProvider>
				</div>
			)}
		</>
	);
}

export default ModelActivities;
