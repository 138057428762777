import React from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Box } from "@mui/material";

import SessionService from "../../service/SessionService";

function Resources({ loading, setLoading }) {
	let allowAllAdmins =
		SessionService.canSystemAdmin() ||
		SessionService.canRtoAdmin() ||
		SessionService.canIsoAdmin() ||
		SessionService.canMasterAgentAdmin() ||
		SessionService.canOrganizationAdmin() ||
		SessionService.canCompanyAdmin() ||
		SessionService.canStoreAdmin();

	return (
		<>
			<div className="rto_form-sections">
				<div className="rto_content-div" style={{ maxWidth: "1600px" }}>
					<Box
						display="flex"
						justifyContent="flex-end"
						backgroundColor="#f5f5f5"
						p={(0.5, 1)}
						borderRadius={1}
					>
						<Breadcrumbs aria-label="breadcrumb">
							<Link to="/dashboard" className="rto_link-2">
								Dashboard
							</Link>
							<Typography>Resources</Typography>
						</Breadcrumbs>
					</Box>

					<div className="rto_title-div">
						<h1 className="rto_page-title">Resources</h1>
					</div>

					<div>
						<div className="rto_results-container">
							<div className="rto_options-container">
								{allowAllAdmins && (
									<>
										<Link
											to="/resources/rto-mobile-fees"
											className="rto_link-block w-inline-block"
											style={{
												marginLeft: "auto",
												marginRight: "auto",
												marginBottom: "25px",
											}}
										>
											<div className="rto_div-block-4">
												<div className="rto_text-block-2">
													RTO Mobile Fees
												</div>
											</div>
										</Link>
									</>
								)}

								<Link
									to="/resources/android-software-installation-instructions"
									className="rto_link-block w-inline-block"
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "25px",
									}}
								>
									<div className="rto_div-block-4">
										<div className="rto_text-block-2">
											Android Installation Instructions
										</div>
									</div>
								</Link>

								<Link
									to="/resources/apple-software-installation-instructions"
									className="rto_link-block w-inline-block"
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "25px",
									}}
								>
									<div className="rto_div-block-4">
										<div className="rto_text-block-2">
											Apple Installation Instructions
										</div>
									</div>
								</Link>

								<Link
									to="/resources/merchant-referral-program-rules"
									className="rto_link-block w-inline-block"
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "25px",
									}}
								>
									<div className="rto_div-block-4">
										<div className="rto_text-block-2">
											Referral Program Rules
										</div>
									</div>
								</Link>

								<a
									href="https://www.dropbox.com/sh/1swlo498jtrio9s/AABNkMOyBAbiktLTOi7E6E6Za?dl=0"
									className="rto_link-block w-inline-block"
									rel="noreferrer"
									style={{
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: "25px",
									}}
									target="_blank"
								>
									<div className="rto_div-block-4">
										<div className="rto_text-block-2">
											Additional Items
										</div>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Resources;
