export const addCreditCardFormDefinition = {
	"form": {
		"state": false,
		"class": "form-vertical"
	},
	"fields": {
		"first_name": {
			"type": "text",
			"label": "First Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"last_name": {
			"type": "text",
			"label": "Last Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": false,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"billing_address": {
			"type": "text",
			"label": "Billing Address",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"zip": {
			"type": "zip",
			"label": "Zip",
			"value": "",
			"required": true,
			"readOnly": false,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"city": {
			"type": "text",
			"label": "City",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"state": {
			"type": "text",
			"label": "State",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"email_address": {
			"type": "email",
			"label": "Email Address",
			"value": "",
			"minLength": 3,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"phone": {
			"type": "tel",
			"label": "Phone Number",
			"value": "",
			"minLength": 1,
			"maxLength": 20,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"prefix": "+1",
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_number": {
			"type": "text",
			"label": "Card Number",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_month": {
			"type": "select",
			"label": "Month",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"options": [
				{
					"name": "January",
					"id": "01"
				},
				{
					"name": "February",
					"id": "02"
				},
				{
					"name": "March",
					"id": "03"
				},
				{
					"name": "April",
					"id": "04"
				},
				{
					"name": "May",
					"id": "05"
				},
				{
					"name": "June",
					"id": "06"
				},
				{
					"name": "July",
					"id": "07"
				},
				{
					"name": "August",
					"id": "08"
				},
				{
					"name": "September",
					"id": "09"
				},
				{
					"name": "October",
					"id": "10"
				},
				{
					"name": "November",
					"id": "11"
				},
				{
					"name": "December",
					"id": "12"
				}
			],
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_year": {
			"type": "select",
			"label": "Year",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"options": (() => {
				const currentYear = new Date().getFullYear();
				const startYear = currentYear; // Start this year
				const endYear = startYear + 10; // End at a decade from the start year
				let options = [];
				for (let year = startYear; year <= endYear; year++) {
					options.push({ "name": year.toString(), "id": year.toString() });
				}
				return options;
			})(),
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_cvv": {
			"type": "text",
			"label": "CVV",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
	},
	"layout": [
		{
			"type": "div",
			"class": "row",
			"items": [
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "first_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "last_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "billing_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "zip",
									"name": "zip",
									"targetCity": "city",
									"targetState": "state",
									"class": "col-md-3 mb-3"
								},
								{
									"type": "input",
									"name": "city",
									"class": "col-md-6 mb-3"
								},
								{
									"type": "input",
									"name": "state",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "email_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "phone",
									"name": "phone",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "card_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "select",
									"name": "card_month",
									"class": "col-md-5 mb-3"
								},
								{
									"type": "select",
									"name": "card_year",
									"class": "col-md-4 mb-3"
								},
								{
									"type": "input",
									"name": "card_cvv",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
			]
		}
	],
	"buttons": [
		{
			"type": "submit",
			"label": "Submit",
			"align": "right",
			"color": "success"
		}
	]
};

export const addACHFormDefinition = {
	"form": {
		"state": false,
		"class": "form-vertical"
	},
	"fields": {
		"first_name": {
			"type": "text",
			"label": "First Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"last_name": {
			"type": "text",
			"label": "Last Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": false,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"billing_address": {
			"type": "text",
			"label": "Billing Address",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"zip": {
			"type": "zip",
			"label": "Zip",
			"value": "",
			"required": true,
			"readOnly": false,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"city": {
			"type": "text",
			"label": "City",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"state": {
			"type": "text",
			"label": "State",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"email_address": {
			"type": "email",
			"label": "Email Address",
			"value": "",
			"minLength": 3,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"phone": {
			"type": "tel",
			"label": "Phone Number",
			"value": "",
			"minLength": 1,
			"maxLength": 20,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"prefix": "+1",
			"style": {
				"textTransform": "uppercase"
			}
		},
		"account_number": {
			"type": "text",
			"label": "Account Number",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"routing_number": {
			"type": "text",
			"label": "Routing Number",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
	},
	"layout": [
		{
			"type": "div",
			"class": "row",
			"items": [
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "first_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "last_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "billing_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "zip",
									"name": "zip",
									"targetCity": "city",
									"targetState": "state",
									"class": "col-md-3 mb-3"
								},
								{
									"type": "input",
									"name": "city",
									"class": "col-md-6 mb-3"
								},
								{
									"type": "input",
									"name": "state",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "email_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "phone",
									"name": "phone",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "account_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "routing_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
			]
		}
	],
	"buttons": [
		{
			"type": "submit",
			"label": "Submit",
			"align": "right",
			"color": "success"
		}
	]
};

export const updateCreditCardFormDefinition = {
	"form": {
		"state": false,
		"class": "form-vertical"
	},
	"fields": {
		"first_name": {
			"type": "text",
			"label": "First Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"last_name": {
			"type": "text",
			"label": "Last Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": false,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"billing_address": {
			"type": "text",
			"label": "Billing Address",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"zip": {
			"type": "zip",
			"label": "Zip",
			"value": "",
			"required": true,
			"readOnly": false,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"city": {
			"type": "text",
			"label": "City",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"state": {
			"type": "text",
			"label": "State",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"email_address": {
			"type": "email",
			"label": "Email Address",
			"value": "",
			"minLength": 3,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"phone": {
			"type": "tel",
			"label": "Phone Number",
			"value": "",
			"minLength": 1,
			"maxLength": 20,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"prefix": "+1",
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_number": {
			"type": "text",
			"label": "Card Number (Token)",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_month": {
			"type": "select",
			"label": "Month",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"options": [
				{
					"name": "January",
					"id": "01"
				},
				{
					"name": "February",
					"id": "02"
				},
				{
					"name": "March",
					"id": "03"
				},
				{
					"name": "April",
					"id": "04"
				},
				{
					"name": "May",
					"id": "05"
				},
				{
					"name": "June",
					"id": "06"
				},
				{
					"name": "July",
					"id": "07"
				},
				{
					"name": "August",
					"id": "08"
				},
				{
					"name": "September",
					"id": "09"
				},
				{
					"name": "October",
					"id": "10"
				},
				{
					"name": "November",
					"id": "11"
				},
				{
					"name": "December",
					"id": "12"
				}
			],
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_year": {
			"type": "select",
			"label": "Year",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"options": (() => {
				const currentYear = new Date().getFullYear();
				const startYear = currentYear; // Start this year
				const endYear = startYear + 10; // End at a decade from the start year
				let options = [];
				for (let year = startYear; year <= endYear; year++) {
					options.push({ "name": year.toString(), "id": year.toString() });
				}
				return options;
			})(),
			"style": {
				"textTransform": "uppercase"
			}
		},
		"card_cvv": {
			"type": "text",
			"label": "CVV",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
	},
	"layout": [
		{
			"type": "div",
			"class": "row",
			"items": [
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "first_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "last_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "billing_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "zip",
									"name": "zip",
									"targetCity": "city",
									"targetState": "state",
									"class": "col-md-3 mb-3"
								},
								{
									"type": "input",
									"name": "city",
									"class": "col-md-6 mb-3"
								},
								{
									"type": "input",
									"name": "state",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "email_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "phone",
									"name": "phone",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "card_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "select",
									"name": "card_month",
									"class": "col-md-5 mb-3"
								},
								{
									"type": "select",
									"name": "card_year",
									"class": "col-md-4 mb-3"
								},
								{
									"type": "input",
									"name": "card_cvv",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
			]
		}
	],
	"buttons": [
		{
			"type": "submit",
			"label": "Submit",
			"align": "right",
			"color": "success"
		}
	]
};

export const updateACHFormDefinition = {
	"form": {
		"state": false,
		"class": "form-vertical"
	},
	"fields": {
		"first_name": {
			"type": "text",
			"label": "First Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"last_name": {
			"type": "text",
			"label": "Last Name",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": false,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"billing_address": {
			"type": "text",
			"label": "Billing Address",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"zip": {
			"type": "zip",
			"label": "Zip",
			"value": "",
			"required": true,
			"readOnly": false,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"city": {
			"type": "text",
			"label": "City",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"state": {
			"type": "text",
			"label": "State",
			"value": "",
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"email_address": {
			"type": "email",
			"label": "Email Address",
			"value": "",
			"minLength": 3,
			"maxLength": 255,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"phone": {
			"type": "tel",
			"label": "Phone Number",
			"value": "",
			"minLength": 1,
			"maxLength": 20,
			"required": true,
			"readOnly": false,
			"upperCase": true,
			"prefix": "+1",
			"style": {
				"textTransform": "uppercase"
			}
		},
		"account_number": {
			"type": "text",
			"label": "Account Number (Token)",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		"routing_number": {
			"type": "text",
			"label": "Routing Number",
			"value": "",
			"minLength": 1,
			"maxLength": 255,
			"required": true,
			"readOnly": true,
			"upperCase": true,
			"style": {
				"textTransform": "uppercase"
			}
		},
		// "acct_id": {
		// 	"type": "text",
		// 	"label": "Account ID",
		// 	"value": "",
		// 	"minLength": 1,
		// 	"maxLength": 255,
		// 	"required": true,
		// 	"readOnly": false,
		// 	"upperCase": true,
		// 	"style": {
		// 		"textTransform": "uppercase"
		// 	}
		// }
	},
	"layout": [
		{
			"type": "div",
			"class": "row",
			"items": [
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "first_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "last_name",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "billing_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row",
							"items": [
								{
									"type": "zip",
									"name": "zip",
									"targetCity": "city",
									"targetState": "state",
									"class": "col-md-3 mb-3"
								},
								{
									"type": "input",
									"name": "city",
									"class": "col-md-6 mb-3"
								},
								{
									"type": "input",
									"name": "state",
									"class": "col-md-3 mb-3"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "email_address",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "phone",
									"name": "phone",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "account_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				{
					"type": "div",
					"class": "col-md-6",
					"items": [
						{
							"type": "div",
							"class": "row mb-3",
							"items": [
								{
									"type": "input",
									"name": "routing_number",
									"class": "col-md-12"
								}
							]
						}
					]
				},
				// {
				// 	"type": "div",
				// 	"class": "col-md-6",
				// 	"items": [
				// 		{
				// 			"type": "div",
				// 			"class": "row mb-3",
				// 			"items": [
				// 				{
				// 					"type": "input",
				// 					"name": "acct_id",
				// 					"class": "col-md-12"
				// 				}
				// 			]
				// 		}
				// 	]
				// }
			]
		}
	],
	"buttons": [
		{
			"type": "submit",
			"label": "Submit",
			"align": "right",
			"color": "success"
		}
	]
};

export const createAuthorizationFormDefinition = {
	form: {
		state: false,
		class: "form-vertical",
	},
	fields: {
		struct_store_id: {
			type: "hidden",
			label: "Store ID",
			value: "",
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		profile_id: {
			type: "hidden",
			label: "Profile ID",
			value: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		token: {
			type: "hidden",
			label: "Token",
			value: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		amount: {
			type: "ccn_amount",
			label: "Amount",
			value: "",
			decimalLimit: 2,
			integerOnly: false,
			prefix: "$",
			suffix: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				textTransform: "uppercase",
			},
		},
		total: {
			type: "hidden",
			label: "Total",
			value: "",
			decimalLimit: 2,
			integerOnly: false,
			prefix: "$",
			suffix: "",
			minLength: 1,
			maxLength: 255,
			required: true,
			readOnly: false,
			upperCase: true,
			style: {
				display: "none",
			},
		},
		total_label: {
			type: "typography",
			text: "Total: $0.00",
			variant: "body1",
			className: "text-lg text-gray-500 font-medium mb-0",
			style: {
				backgroundColor: "#f3f4f6",
				color: "#6b7280",
				padding: "0.5rem",
				borderRadius: "0.25rem",
				fontFamily: "inter",
				fontSize: "20px",
				fontWeight: "500",
			},
		},
	},
	layout: [
		{
			type: "div",
			class: "row",
			items: [
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "struct_store_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "profile_id",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "input",
									name: "token",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "ccn_amount",
									name: "amount",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "row mb-3",
							items: [
								{
									type: "number",
									name: "total",
									class: "col-md-12",
								},
							],
						},
					],
				},
				{
					type: "div",
					class: "col-12",
					items: [
						{
							type: "div",
							class: "mb-0",
							items: [
								{
									type: "typography",
									name: "total_label",
									class: "col-md-12",
								},
							],
						},
					],
				},
			],
		},
	],
	buttons: [
		{
			type: "submit",
			label: "Process Transaction",
			align: "right",
			color: "success",
		},
	],
};